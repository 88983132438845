$company-color: #ffcb08;
$button-text: #3a3428;









$accent-color: #faac32;
$accent-color-alt: #f58034;

$silver: #aeb2bc;
$searchResults: #31394c;
$searchResultsHover: #282d3a;

$popup-bg: linear-gradient(to bottom, #4a5267, #282d3a);
$blue-light: #5c6578;
$soft-red:    #f05e45;
$soft-yellow: #eacf4e;
$soft-blue:   #18e1ff;
$soft-green:  #02e2ae;

$accent: #0081ff;
$window-bg: linear-gradient(to bottom, #4d556b, #242a38);
$transparent: #7b88a2;
$font-shadow: 0 1px 1.5px rgba(0, 0, 0, 0.3);

$line-color: #073b9a;
$window-header: #141727;

$active-color: #2870d0;
$semiactive-color: #b7c4de;
$inactive-color: #7b88a2;



$green-g: linear-gradient(to bottom, #8cff8e, #2ddd5f);
$purple-g: linear-gradient(to bottom, #b290ff, #744ef8);
$orange-g: linear-gradient(to bottom, #faac32, #f58034);

$btn-g: linear-gradient(to bottom, #2f3440 0%, #2f3440 33%, #faac32 34%, #f58034 100%);

$gr-green: linear-gradient(to bottom, #a8e063, #56ab2f);
$gr-red: linear-gradient(to bottom, #e35d5b, #e53935);


