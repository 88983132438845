@import './colors.sass';
@import './mixin.sass';

.MainHeader {
    display: flex;
    height: 90px;
    font-size: 16px;
    align-items: center;
    justify-content: space-between;
    // position: fixed
    position: absolute;
    width: 100%;
    padding: 0 100px;
    z-index: 10;
    .Language {
        display: flex;
        margin-right: 50px;
        border: 2px solid white;
        height: 30px;
        border-radius: 5px;
        align-items: center;
        position: relative;
        @include screen-m {
            margin-right: 20px; }
        button {
            padding: 0 10px;
            display: flex;
            height: 100%;
            align-items: center;
            text-decoration: none;
            color: white;
            transition: .2s ease;
            font-size: 12px;
            background: transparent;
            border: none;
            cursor: pointer;
            &:hover {
                background: rgba(255,255,255,.8);
                color: black; }
            &.active {
                background: white;
                color: black;
                font-weight: 500; } } }
    &.h {
        // background: $button-text
 }        // box-shadow: 0 5px 10px rgba(0,0,0,.3)

    .Logo {
        width: 170px; }
    .MainMenu {
        font-family: "HelveticaBold";
        color: white;
        font-size: 18px;
        display: flex;
        align-items: center;
        height: 100%;
        .BasketToggle {
            position: relative;
            .BasketCount {
                position: absolute;
                top: -5px;
                left: 20px;
                z-index: 5;
                background: $company-color;
                color: $button-text;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 3px 5px 0px 5px;
                box-shadow: -5px 5px 5px rgba(0,0,0,.5);
                border-radius: 100px;
                font-size: 14px;
                opacity: 0;
                transform: translateY(-30px);
                visibility: hidden;
                transition: .3s ease;
                &.active {
                    opacity: 1;
                    transform: translateY(0);
                    visibility: visible; } }
            svg {
                fill: white;
                width: 30px; } }
        a {
            cursor: pointer;
            letter-spacing: 1px;
            text-decoration: none;
            color: white;
            margin-right: 50px; }
        @include screen-s {
            margin-right: 10px; }

        .textLink {
            font-size: 13px; } }

    @include screen-m {
        padding: 0 20px;
        height: 60px;
        a {
            display: none; } } }

.MobileMenuWrapper {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0);
    visibility: hidden;
    transition: background .4s ease, visibility .4s ease;
    transition-delay: .2s;
    .MobileMenu {
        width: 340px;
        background: #282d3a;
        height: 100%;
        transition: transform .4s ease;
        transition-delay: .0s;
        transform: translateX(-100%);
        .Controls {
            height: 60px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 20px;
            i {
                color: white;
                font-size: 25px;
                cursor: pointer; } }
        a {
            display: block;
            background: rgba(255,255,255,.3);
            text-decoration: none;
            line-height: 35px;
            margin-bottom: 3px;
            &:hover {
                opacity: .7; } } }
    &.active {
        background: rgba(0,0,0,.7);
        visibility: visible;
        transition-delay: .0s;
        .MobileMenu {
            transform: translateX(0);
            transition-delay: .2s; } } }

