@import './colors.sass';
@import './mixin.sass';

.slick-list,
.slick-slider,
.slick-track {
    position: relative;
    display: block; }

.slick-loading .slick-slide,
.slick-loading .slick-track {
    visibility: hidden; }

.slick-slider {
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y; }

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0; }

.slick-list:focus {
    outline: 0; }

.slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-list,
.slick-slider .slick-track {
    transform: translate3d(0, 0, 0); }

.slick-track {
    top: 0;
    left: 0; }

.slick-track:after,
.slick-track:before {
    display: table;
    content: ''; }

.slick-track:after {
    clear: both; }

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px; }

[dir=rtl] .slick-slide {
    float: right; }

.slick-slide img {
    display: block; }

.slick-slide.slick-loading img {
    display: none; }

.slick-slide.dragging img {
    pointer-events: none; }

.slick-initialized .slick-slide {
    display: block; }

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
    display: none; }

.MainSlider {
    margin-top: 60px;
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100%;
    button {
        color: transparent;
        display: block;
        font-size: 0px;
        height: 50px;
        width: 30px;
        border: none;
        margin: 0 8px;
        overflow: hidden;
        flex-shrink: 0;
        background-size: 100% 100%;
        background-position: center;
        background-color: transparent;
        &.slick-prev {
            background-image: url(../svg/arr-left.svg); }
        &.slick-next {
            background-image: url(../svg/arr-right.svg); } }


    .slick-track {
        min-width: 9999px!important; }

    .slick-list {
        overflow: hidden; }
    .Dots {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        .Control {
            font-size: 20px;
            transition: .3s ease;
            margin: 0 10px;
            cursor: pointer;
            color: white;
            opacity: 1;
            &:hover {
                opacity: 1; } }
        .Dot {
            color: #3d465e;
            font-size: 8px;
            transition: .3s ease;
            margin: 0 10px;
            cursor: pointer;
            &:hover, &.active {
                color: $accent; } } } }
.slick-slider {
    display: flex;
    align-items: center;
    * {
        outline: none; } }
