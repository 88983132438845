.FlipWrapper {
    perspective: 150px;
    &.v {
        position: relative;
        .Back {
            transform: rotateX(180deg); }
        .Flip {
            transform-origin: 100% 50%; } }

    .Flip {
        height: 100%;
        transition: 0.4s ease-in-out;
        transform-style: preserve-3d;
        position: relative; } }

.FlipWrapper.flipped .Flip {
		transform: rotateY(180deg); }

.FlipWrapper.v.flipped .Flip {
    transform: rotateX(-180deg); }

.FlipWrapper, .Front, .Back {
	width: 100%;
	height: 100%; }

.Front, .Back {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0; }

.Front {
    z-index: 2;
    height: 100%;
    width: 100%;
    transform: rotateY(0deg); }

.Back {
    transform: rotateY(180deg);
    height: 100%;
    width: 100%; }
.vertical .Back {
    transform: rotateX(180deg); }
.vertical.FlipWrapper.Flip {
    transform-origin: 100% 213.5px; }
.vertical.FlipWrapper.flipped .Flip {
    transform: rotateX(-180deg); }
