@import './colors.sass';
@import './mixin.sass';

@font-face {
	font-family: 'Gagalin';
	src: url('../fonts/Gagalin-Regular.eot');
	src: local('Gagalin Regular'), local('Gagalin-Regular'), url('../fonts/Gagalin-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Gagalin-Regular.woff') format('woff'), url('../fonts/Gagalin-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal; }

@mixin font-face($family, $path, $svg, $weight: normal, $style: normal) {
  @font-face {
    font-family: $family;
    src: url('#{$path}.eot');
    src: url('#{$path}.eot?#iefix') format('embedded-opentype'), url('#{$path}.woff') format('woff'), url('#{$path}.ttf') format('truetype'), url('#{$path}.svg##{$svg}') format('svg');
    font-weight: $weight;
    font-style: $style; } }

@include font-face('HelveticaBlack', '../fonts/HelveticaNeueCyr-Black', 'HelveticaBlack');
@include font-face('HelveticaBold', '../fonts/HelveticaNeueCyr-Bold', 'HelveticaBold');
@include font-face('HelveticaRoman', '../fonts/HelveticaNeueCyr-Roman', 'HelveticaRoman');


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

body {
    background-color: #24211f; }

.disablescroll {
    overflow: hidden;
    height: 80vh; }

.App {
    background-image: url(../img/bg.png);
    background-position: top center;
    background-size: 100% auto;
    background-color: #24211f;
    transition: .5s ease;
    // filter: blur(0px)
    &.blurred {
       filter: blur(5px);
       user-select: none; } }

button {
    &:active, &:focus {
        outline: none; } }

section {
    min-height: 600px;
    width: 100vw;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-bottom: 1px solid red
    &:first-of-type {
        min-height: 100vh; }
    &.VideoBlockSection {
        min-height: 400px; } }

h1 {
    text-align: center;
    margin-bottom: 30px;
    color: white;
    font-size: 30px;
    text-transform: uppercase;
    line-height: 44px;
    font-family: "HelveticaBlack", sans-serif;
    span {
        color: $company-color; } }

.btn {
    display: block;
    margin: 0 auto;
    background: $company-color;
    color: $button-text;
    font-size: 18px;
    padding: 20px 80px;
    border-radius: 100px;
    border: none;
    text-transform: uppercase;
    font-family: "HelveticaBold", sans-serif;
    box-shadow: 0 0 30px $company-color;
    cursor: pointer; }

h4 {
    font-family: "HelveticaRoman";
    text-align: center;
    color: white;
    line-height: 30px;
    margin-bottom: 40px; }

.ButtonBlock {
    padding: 0 20px;

    width: 100%; }

.MainBlock {
    padding-top: 80px; }

.FeaturesBlock {
    .Features {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        padding: 0 100px;
        flex-wrap: wrap;
        .Feature {
            text-align: center;
            width: 25%;
            padding: 80px 30px 0;
            h3 {
                color: $company-color;
                font-family: "HelveticaBold", sans-serif;
                font-size: 20px;
                margin-top: 50px;
                margin-bottom: 30px; }
            p {
                font-family: "HelveticaRoman";
                font-size: 16px;
                line-height: 30px;
                color: white; }
            svg {
                height: 90px;
                width: 100%;
                fill: white; } }
        &::before {
            content: "";
            display: block;
            height: 200px;
            width: 17.5%;
            background: url(../img/1.png);
            background-size: 100%;
            position: absolute;
            left: 0;
            top: -150px; }
        &::after {
            content: "";
            display: block;
            height: 200px;
            width: 17.5%;
            background: url(../img/1.png);
            background-size: 100%;
            position: absolute;
            transform: rotate(180deg);
            right: 0;
            bottom: -200px; }

        @include screen-xl {
            padding: 0 20px;
            &::before, &::after, {
                display: none; }
            .Feature {
                width: 40%;
                padding: 10px 20px;
                margin-bottom: 20px;
                h3 {
                    margin-top: 15px;
                    margin-bottom: 10px; }
                svg {
                    height: 70px; } } }
        @include screen-s {
            padding: 0 20px;
            &::before, &::after, {
                display: none; }
            .Feature {
                width: 100%;
                padding: 20px 10px;
                margin-bottom: 20px;
                h3 {
                    font-size: 30px;
                    margin-top: 20px;
                    margin-bottom: 10px; }
                p {
                    font-size: 18px;
                    line-height: 26px; }
                svg {
                    height: 90px; } } } } }

.VideoBlock {
    padding: 100px 0;
    .Video {
        background: grey;
        border-radius: 25px;
        position: relative;
        padding-bottom: 56%;
        width: 820px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: calc(100% + 20px);
            height: calc(100% + 20px);
            background: url(../img/video.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            left: -10px;
            top: -10px; }
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 160px;
            height: 200px;
            left: -160px;
            top: -80px; }

        &.EN::after {
            background: url(../img/video-text-en.png);
            background-size: 100%;
            background-repeat: no-repeat; }
        &.RU::after {
            background: url(../img/video-text-ru.png);
            background-size: 100%;
            background-repeat: no-repeat; }
        &.UA::after {
            background: url(../img/video-text-ua.png);
            background-size: 100%;
            background-repeat: no-repeat; } }

    @include screen-m {
        padding: 30px 0;
        .Video {
            width: 100vw;
            border-radius: 0;
            &::before {
                display: none; }
            &::after {
                left: calc(50% - 100px);
                overflow: hidden;
                height: 60px;
                background-size: 100% 250%; } } } }


.ProductsBlock {
    width: 100%;
    h1 {
        margin-bottom: 60px; }
    .Products {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0 20px;
        position: relative;
        .Product {
            position: relative;
            text-align: center;
            width: 40%;
            height: 400px;
            padding: 60px 30px;
            margin: 0px 20px 40px;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            .Img {
                width: 15%;
                height: 100%;
                display: flex;
                overflow: hidden;
                align-items: center;
                justify-content: center;
                img {
                    height: 100%; } }
            .Details {
                padding-left: 10px;
                width: 80%;
                display: flex;
                overflow: hidden;
                flex-direction: column;
                text-align: left;
                z-index: 3;
                button {
                    margin: 0;
                    box-shadow: none;
                    width: 160px;
                    padding: 15px 30px;
                    font-size: 14px;
                    cursor: pointer;
                    transition: .3s ease;
                    &:hover, &:active {
                        outline: none;
                        opacity: .8; } } }
            h2 {
                font-size: 20px;
                color: white;
                font-family: "HelveticaBold", sans-serif;
                margin-bottom: 8px;
                text-transform: uppercase; }
            .PriceGroup {
                display: flex;
                align-items: center;
                .OptLabel {
                    color: $company-color;
                    font-size: 11px;
                    display: inline-block;
                    font-family: "HelveticaBold";
                    line-height: 16px;
                    padding-top: 1px;
                    color: white;
                    i {
                        opacity: .5;
                        font-style: normal; }
                    span {
                        color: $company-color; } }
                .PriceLabel {
                    display: inline-block;
                    background: $company-color;
                    color: transparentize($button-text, .5);
                    font-size: 10px;
                    padding:  6px 4px;
                    font-family: "HelveticaBold";
                    margin-right: 10px;
                    .Big {
                        margin: 0 3px;
                        font-size: 18px;
                        font-family: "HelveticaRoman";
                        color: $button-text; } } }

            p {
                font-family: "HelveticaRoman";
                font-size: 16px;
                line-height: 30px;
                color: white;
                flex-grow: 1;
                width: 90%;
                margin: 7px 0; }
            &::before {
                content: "";
                display: block;
                z-index: 1;
                height: 100%;
                width: 100%;
                background: url(../img/video.png);
                background-repeat: no-repeat;
                background-size: 100% 100%;
                position: absolute;
                left: 0;
                top: 0; } }

        @include screen-l {
            padding: 0 20px;
            .Product {
                width: 100%;
                &::before {
                    background-image: linear-gradient(to right, transparent, $company-color 20%, $company-color 80%,  transparent);
                    height: 1px; } } } } }
.ContactsSection {
    padding-top: 30px;
    flex-wrap: wrap;
    background: $button-text;
    min-height: 400px;
    box-shadow: 0 -5px 30px rgba(0,0,0,.3);
    h5 {
        width: 100%;
        text-align: center;
        font-family: "HelveticaBold", sans-serif;
        color: $company-color;
        font-size: 18px;
        padding-top: 20px;
        padding-bottom: 20px; }
    .ContactsWrapper {
        width: 100%;
        padding: 0 100px;
        display: flex;
        align-items: center;
        flex-wrap: wrap-reverse;
        justify-content: space-between;
        .Feedback {
            width: 30%;
            * {
                border-color: white!important; }
            .FormWrapper {
                width: 100%;
                margin: 0;
                .Input {
                    height: 50px;
                    label {
                        top: 16px; }
                    input {
                        padding-top: 14px; } } }
            .SubmitButtons {
                padding-top: 0px;
                button {
                    border: 2px solid white;
                    border-top: none;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    background: transparent;
                    color: $company-color;
                    text-shadow: none;
                    transition: background .3s ease;
                    font-family: "HelveticaBold", sans-serif;
                    letter-spacing: 1px;

                    &.active {
                        background: $company-color;
                        color: $button-text; } } } }
        .FooterLogoWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 30%;
            img {
                width: 160px; } }
        h3 {
            color: $company-color;
            font-family: "HelveticaBold", sans-serif;
            font-size: 24px;
            margin-bottom: 20px; }
        .Contacts {
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            p {
                color: white;
                font-size: 16px;
                font-family: "HelveticaBold", sans-serif;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                svg {
                    margin-right: 30px;
                    width: 25px;
                    height: 25px;
                    fill: white; } } }

        @include screen-l {
            .FooterLogoWrapper {
                display: none; }
            .Feedback, .Contacts {
                width: 45%; } }
        @include screen-m {
            padding: 0 40px;
            align-items: flex-start;
            .Feedback, .Contacts {
                width: 100%!important; } } } }

.PartnersBlock {
    width: 100%;
    padding: 0 100px;
    h4 {
        text-align: left; }
    ._Slide {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 100px;
        img {
            height: 120px;
            display: block;
            margin: 0 auto; } }

    @include screen-m {
        padding: 0 20px;
        h4 {
            padding: 0 20px; }
        ._Slide {
            padding: 0 40px;
            img {
                height: 100px; } } }

    @include screen-s {
        padding: 0 0px;
        h4 {
            padding: 0 20px; }
        ._Slide {
            padding: 0 100px;
            img {
                height: 70px; } } } }

.Section {
    position: relative;
    overflow: hidden;
    background: #1b1c26;
    min-height: calc(100vh - 60px); }

.InpSelect {
    display: inline-block;
    position: relative;
    .Option {
        color: $inactive-color;
        transition: color .3s ease;
        user-select: none;
        display: inline-block;
        cursor: pointer;
        &:hover, &:active {
            color: $semiactive-color; }
        &.Active {
            color: $active-color;
            font-weight: 500; } }
    .Option:not(:first-of-type) {
        margin-left: 20px; }

    .Indicator {
        transition: transform .0s ease;
        width: 100px;
        transform-origin: left;
        height: 2px;
        position: absolute;
        background: $accent; } }

.Dropdown {
    position: relative;
    user-select: none;
    z-index: 10;
    span {
        padding-right: 2px;
        color: $inactive-color;
        transition: .3s ease;
        cursor: pointer;
        &:hover {
            color: $semiactive-color; } }
    .Caret {
        margin-top: 5px;
        color: #2e314a;
        transition: .3s ease; }
    .DropdownList {
        z-index: -15;
        opacity: 0;
        visibility: none;
        transform: translateY(15px);
        position: absolute;
        left: 0;
        top: 100%;
        margin-top: 2px;
        background: transparentize($window-header, 0.07);
        transition: max-height 0s ease 0.3s, transform .3s ease, opacity .3s ease, visibility .3s ease;
        min-width: 150px;
        max-height: 0px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        overflow: hidden;
        width: 10px;
        .DropdownOption {
            font-size: 12px;
            padding: 2px 10px;
            display: flex;
            align-items: center;
            color: white;
            cursor: pointer;
            span {
                color: $inactive-color; }
            .DropdownCheckBox {
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .4s ease;
                width: 16px;
                height: 16px;
                background: $transparent;
                border-radius: 2px;
                i {
                    text-shadow: 0 1px 1px rgba(0,0,0,.3);
                    font-size: 10px;
                    opacity: 0;
                    transition: .2s ease;
                    transform: scale(0.8);
                    color: $semiactive-color; } }
            &:hover {
                span {
                    color: $inactive-color; } }
            &.checked {
                .DropdownCheckBox {
                    background: $accent;
                    i {
                        opacity: 1;
                        transform: scale(1);
                        color: $semiactive-color; } }
                span {
                    color: $semiactive-color; } } } }

    &.filled {
        .Caret {
            color: $accent;
            opacity: 1; } }


    .Indicator {
        transition: .3s ease;
        width: 100%;
        transform-origin: left;
        height: 2px;
        position: absolute;
        background: $accent;
        opacity: 0; }
    &.active {
        .DropdownList {
            z-index: 5;
            opacity: 1;
            max-height: 250px;
            transition: max-height 0s, transform .3s ease, opacity .3s ease, visibility .3s ease;
            overflow: auto;
            visibility: visible;
            transform: translateY(0); }

        .Caret {
            transform: rotate(180deg); }
        .Indicator, span {
            opacity: 1; } } }

.EditorsWrapper {
    padding-top: 55px;
    width: 50%;
    @include screen-l {
        width: 100%;
        margin: 5px 7.5% 0; } }

.load {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    transition: .3s ease;
    height: 150px;
    &.loaded {
        opacity: 0;
        transform: translate(-50%, -50%) scale(.3); } }

.load hr {
    border: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 0;
    animation: spin 2s ease infinite;
    border: 1px solid white; }


.load hr:nth-child(1) {
    border: 1px solid white;
    animation-delay: -1.5s; }

.load hr:nth-child(4) {
    top: 20%;
    left: 20%;
    width: 60%;
    height: 60%; }

.load hr:nth-child(2) {
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    animation-delay: -1s; }

.load hr:nth-child(3) {
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    animation-delay: -0.5s; }

.Preloader {
    visibility: visible;
    opacity: 1;
    background: #212734;
    z-index: 999;
    background-size: cover;
    background-position: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: .5s ease;
    img {
        width: 150%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        filter: blur(6px);
        @include screen-s {
            transform: translate(-50%, -50%) rotate(90deg);
            width: 130vh; } }
    &.loaded {
        visibility: hidden;
        opacity: 0; } }

@keyframes spin {
    0%, 100% {
        transform: translate(0); }
    25% {
        transform: translate(160%); }
    50% {
        transform: translate(160%, 160%); }
    75% {
        transform: translate(0, 160%); } }


.Admin {
    min-height: 100vh;
    ._BlockTitle {
        font-family: "HelveticaBold", sans-serif;
        padding-bottom: 10px;
        color: white; }
    .Row {
        display: flex;
        @include screen-l {
            flex-wrap: wrap; } }
    .TextEditor {
        .Input:nth-of-type(1) {
            border-top-right-radius: 0; }
        .Input:nth-of-type(2) {
            border-top-right-radius: 5px; }
        .Input:nth-of-type(3) {
            border-left: 2px solid $company-color; }
        padding-bottom: 20px;
        width: 100%;
        .SubmitButton {
            border-bottom-left-radius: 5px;
            background: $company-color;
            color: $button-text; }
        .FormWrapper {
            background: rgba(0, 0, 0, 0.6);
            width: 100%;
            margin: 0; }
        .Bl {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            background: rgba(0,0,0,.6);
            border-radius: 5px;
            border: 2px solid $company-color;
            button {
                width: 100%;
                border-radius: 0;
                border: none;
                background: $company-color;
                color: $button-text;
                height: 50px;
                font-size: 16px;
                letter-spacing: .5px;
                text-transform: uppercase;
                border-top: 2px solid $company-color;
                font-weight: 600;
                cursor: pointer; }
            .T {
                border-right: 2px solid $company-color;
                width: calc(100% / 3);
                padding-top: 5px;
                label {
                    color: rgba(255,255,255,.4);
                    font-family: "HelveticaRoman", sans-serif;
                    padding-left: 10px;
                    font-size: 14px; }
                textarea {
                    resize: none;
                    width: 100%;
                    height: 150px;
                    font-size: 14px;
                    line-height: 1.4;
                    padding: 5px 10px;
                    background: none;
                    color: white;
                    border: none;
                    &:focus {
                        outline: none; } }

                &:last-of-type {
                    border-right: none; } } } }
    .AdminProds {
        padding-top: 50px;
        width: 42%;
        @include screen-l {
            width: 100%; }
        h3 {
            text-align: center;
            margin-bottom: 10px;
            color: white;
            font-family: "HelveticaRoman", sans-serif; }
        .FormWrapper {
            background: rgba(0,0,0,.6); } }
    .AdminHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px;
        font-family: "HelveticaRoman", sans-serif;
        background: rgba(0,0,0,.3);
        color: white;
        font-size: 16px;
        button {
            border: 2px solid white;
            border-radius: 5px;
            padding: 10px 20px;
            font-size: 16px;
            background: transparent;
            color: white;
            cursor: pointer;
            transition: .3s ease;
            &:hover {
                background: white;
                color: black; } } } }



.gagalin {
    font-family: "Gagalin"; }

.ALTT {
    display: flex;
    flex-direction: column;
    align-items: center;
    .opt-img {
        width: 220px;
        margin-bottom: 30px; }
    p {
        font-size: 22px;
        width: 100%;
        line-height: 1.4;
        color: white;
        text-align: center;
        font-family: "HelveticaBold", sans-serif;
        margin-bottom: 30px;
        padding: 10px 20px; }
    @include screen-s {
        p {
            font-size: 16px; } } }

.buttonsss {
    display: flex;
    padding-bottom: 50px;
    .btn {
        text-decoration: none;
        margin: 0 20px;
        text-align: center; }
    @include screen-s {
        flex-direction: column;
        .btn {
            margin-bottom: 20px;
            padding: 15px 30px; } } }

