@mixin screen-xl {
    @media all and (max-width: 1199px) {
        @content; } }

@mixin screen-l {
    @media all and (max-width: 991px) {
        @content; } }

@mixin screen-m {
    @media all and (max-width: 768px) {
        @content; } }

@mixin screen-s {
    @media all and (max-width: 550px) {
        @content; } }
