@import './colors.sass';
@import './mixin.sass';

$popup-main: #484f5f;

.PopupWrapper {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background: rgba(0,0,0,0);
    align-items: center;
    visibility: hidden;
    transition: .5s ease;
    .FlipWrapper {
        perspective: 3000px; }
    .Flip, .Front, .Back {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
    .Popup {
        transition: transform .3s ease, opacity .3s ease;
        width: 1000px;
        min-height: 200px;
        max-height: 600px;
        background: rgba(0,0,0,.3);
        border: 1px solid $company-color;
        border-radius: 15px;
        transform: scale(0.95);
        opacity: 0;
        box-shadow: 0 0 50px rgba(0,0,0,.5);
        overflow: hidden;
        position: relative;
        -webkit-scroll-behavior: smooth;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        &._statusMessage {
            border: none!important;
            width: 400px;
            font-family: "HelveticaBold", sans-serif;
            text-shadow: 0 2px 2px rgba(0,0,0,.3);
            img {
                width: 80px;
                filter: drop-shadow(0 2px 2px rgba(0,0,0,.1));
                padding-bottom: 10px; }
            button {
                width: 85%;
                background: white;
                border: none;
                border-radius: 5px;
                font-family: "HelveticaBold", sans-serif;
                font-size: 14px;
                color: #5c9c37;
                letter-spacing: 1px;
                padding: 14px 50px 12px;
                transition: .3s ease;
                cursor: pointer;
                text-transform: uppercase;
                &:hover {
                    opacity: .8; } } }
        .ClosePopup {
            width: 20px;
            cursor: pointer; }
        .StatusMessagePopup {
            padding: 20px 0 30px;
            min-height: 200px;
            width: 100%;
            background: $gr-green;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            position: relative;
            color: white;
            i {
                font-size: 80px;
                padding-bottom: 20px; }
            h2 {
                margin-bottom: 8px;
                font-weight: 300; }
            h3 {
                font-size: 14px;
                margin-bottom: 26px;
                font-weight: 300; } }

        .PopupHeader {
            height: 40px;
            display: flex;
            width: 100%;
            align-items: center;
            padding: 50px;
            span {
                flex-grow: 1;
                padding-left: 30px;
                font-size: 30px;
                text-transform: uppercase;
                color: $company-color;
                font-family: "HelveticaBold", sans-serif;
                text-align: center; } }
        .Payload {
            width: 100%;
            text-align: center; } }
    &.active {
        background: rgba(0,0,0,.7);
        visibility: visible;
        .Popup {
            opacity: 1;
            transform: scale(1); } } }

.BasketPopup {
    text-align: right;
    width: 100%;
    .BasketItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        margin: 30px 0;
        padding-bottom: 31px;
        transition: .3s ease;
        // &:nth-of-type(odd)
        //     background: rgba(255,255,255,.1)
        &::before {
            content: "";
            position: absolute;
            background: linear-gradient(to right, transparent, $company-color 10%, $company-color 90%,  transparent);
            height: 1px;
            width: 100%;
            top: -15px;
            left: 0; }
        &:first-of-type::before {
            display: none; }
        .ImgWrapper {
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 100px;
            margin-right: 10px;
            img {
                height: 100%; } }
        .NameWrapper {
            padding-right: 10px;
            font-family: "HelveticaBold", sans-serif;
            width: 100%;
            text-align: left;
            font-size: 16px;
            line-height: 18px; }
        .AmountChanger {
            width: 100px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 50px;
            input {
                height: 30px;
                text-align: center;
                background: none;
                border: none;
                width: 50px;
                border-radius: 0;
                color: $company-color;
                font-family: "HelveticaRoman";
                font-size: 16px;
                border-bottom: 1px solid transparent;
                transition: .3s ease;
                &:active, &:focus {
                    outline: none;
                    border-color: $company-color; } }
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                background: none;
                border: none;
                color: white;
                font-size: 16px;
                cursor: pointer;
                transition: .3s ease;
                &:hover {
                    opacity: .8; } } }
        .RemoveEl {
            display: none;
            margin-right: 10px;
            color: $company-color; }
        .OptionToggle {
            display: none;
            padding: 0 20px;
            user-select: none;
            cursor: pointer;
            flex-grow: 1;
            span {
                font-family: "HelveticaRoman";
                padding-bottom: 3px;
                font-size: 16px;
                border-bottom: 1px solid white; } }
        .PriceWrapper {
            flex-shrink: 0;
            width: 160px;
            text-align: left;
            display: flex;
            flex-direction: column;
            .Price {
                color: $company-color;
                font-family: "HelveticaRoman";
                font-size: 16px;
                span {
                    font-family: "HelveticaBold";
                    font-size: 20px; } }
            .Deal {
                font-family: "HelveticaRoman";
                padding-top: 5px;
                color: white;
                font-size: 14px; } } }

    .Prods {
        width: 90%;
        margin: 0 5%;
        height: 400px;
        position: relative;
        overflow: auto;
        color: white;
        text-align: center;
        -webkit-scroll-behavior: smooth;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch; }
    .Controls {
        padding: 10px 50px 50px;
        button {
            display: inline-block;
            margin: 0 auto;
            margin-left: 20px;
            background: $company-color;
            color: $button-text;
            font-size: 14px;
            padding: 10px 20px;
            border-radius: 100px;
            border: none;
            font-family: "HelveticaBold", sans-serif;
            cursor: pointer;
            &.alt {
                border: 1px solid $company-color;
                background: transparent;
                color: $company-color; } } } }

.PopupWrapper {
    .Popup {
        @include screen-s {
            width: 100%;
            height: 100%;
            max-height: 100%;
            border-radius: 0;
            border: none;
            display: flex;
            flex-wrap: wrap;
            .Prods, .AdminProds {
                height: calc(90vh - 62px)!important;
                width: 98%!important;
                margin: 0 1%!important; }

            .AmountChanger {
                width: 50px;
                margin-right: 10px;
                input {
                    border-color: $company-color; }
                button {
                    display: none; } }

            .PriceWrapper {
                padding-right: 5px; }

            .OptionToggle {
                display: none; }

            .ImgWrapper {
                height: 70px; }

            .RemoveEl {
                display: block; }

            .Controls {
                z-index: 3;
                width: 100%;
                position: absolute;
                bottom: 0;
                text-align: center;
                border-top: 2px solid $company-color;
                padding: 0;
                height: 10vh;
                display: flex;
                button {
                    display: inline-block;
                    margin: 0 auto;
                    background: $company-color;
                    color: $button-text;
                    font-size: 14px;
                    padding: 10px 20px;
                    margin: 0;
                    width: 100%;
                    line-height: 20px;
                    border: none;
                    border-radius: 0;
                    font-family: "HelveticaBold", sans-serif;
                    cursor: pointer;
                    &.alt {
                        border-top: 1px solid $company-color;
                        background: black;
                        color: $company-color; } } }

            .PopupHeader {
                border-bottom:  2px solid $company-color;
                padding: 30px;
                span {
                    padding: 0; } } } } }

.AdminPopup {
    overflow: auto;
    .Controls {
        z-index: 3;
        width: 100%;
        position: absolute;
        bottom: 0;
        text-align: center;
        border-top: 2px solid $company-color;
        padding: 0;
        height: 10vh;
        display: flex;
        button {
            padding-bottom: 40px;
            display: block;
            width: 50%;
            margin: 0;
            height: 15vh;
            border-radius: 0;
            height: auto;
            border: none; }
        button {
            display: inline-block;
            margin: 0 auto;
            background: $company-color;
            color: $button-text;
            font-size: 14px;
            padding: 10px 20px;
            border: none;
            font-family: "HelveticaBold", sans-serif;
            cursor: pointer;
            &.alt {
                border-top: 1px solid $company-color;
                background: transparent;
                color: $company-color;
                background: black; } } }
    .AdminProds {
        z-index: 2;
        width: 90%;
        margin: 0 5%;
        height: 490px;
        position: relative;
        overflow: auto;
        color: white;
        text-align: center;
        -webkit-scroll-behavior: smooth;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        padding-bottom: 100px;
        .Product {
            display: flex; } } }

.OrderPopup {
    padding-bottom: 30px; }

.c-leave {
    opacity: 1; }

.c-leave.c-leave-active {
    opacity: 0;
    transition: 400ms ease 400ms; }

