@import './colors.sass';
@import './mixin.sass';

$border-style: 2px solid $company-color;

.CheckBoxWrapper {
    height: 60px;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .CheckBox {
        height: 30px;
        cursor: pointer;
        &:before {
            content: '';
            position: absolute;
            top: 23px;
            left: 8px;
            height: 24px;
            transition: all .3s ease;
            width: 40px;
            border-radius: 100px;
            background: $blue-light; }
        .CheckBoxTitle {
            position: absolute;
            top: 20px;
            display: inline-block;
            user-select: none;
            line-height: 16px;
            margin-left: 50px;
            text-align: left;
            font-size: 12px;
            transition: all .3s ease;
            color: rgba(255,255,255,.35); }
        .CheckBoxToggle {
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            transition: all .3s ease;
            position: absolute;
            top: 25px;
            z-index: 1;
            left: 10px;
            background: white;
            box-shadow: 0 0 5px rgba(0,0,0,.3); }
        input {
            visibility: hidden; }
        &.active {
            .CheckBoxTitle {
                color: rgba(255,255,255,.8); }
            .CheckBoxToggle {
                left: 26px; }
            &:before {
                background: #26ca28; } } } }


.Select {
    line-height: 50px;
    min-height: 50px;
    text-align: left;
    border: $border-style;
    border-bottom: none;
    position: relative;
    cursor: pointer;
    label {
        position: absolute;
        transition: .3s ease;
        height: 20px;
        font-size: 14px;
        color: rgba(255,255,255,.8);
        padding-left: 15px; }
    .DropdownValue {
        color: white;
        transition: .3s ease;
        font-size: 15px;
        position: absolute;
        padding-top: 9px;
        padding-left: 15px;
        transform: translateY(12px);
        opacity: 0; }
    .DropdownArr {
        right: 10px;
        transition: .3s ease;
        line-height: 50px;
        position: absolute;
        padding: 0 10px;
        color: $blue-light; }
    &.selected {
        label {
            transform: translateY(-12px);
            color: rgba(255,255,255,.4); }
        .DropdownValue {
            transform: translateY(0px);
            opacity: 1; } }
    .Dropdown {
        margin-top: 50px;
        z-index: 2;
        width: 100%;
        transition: .3s ease-in-out;
        background: $blue-light;
        overflow: hidden;
        max-height: 0px;
        overflow: scroll;
        .OptIcon {
            background: #484f5f;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center; }
        li {
            user-select: none;
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 30px;
            padding-left: 5px;
            color: rgba(255,255,255,.6);
            &:hover, &.selected {
                color: white; }
            &:last-of-type {
                border-bottom: none; } } }
    &.active {
        .DropdownArr {
            transform: rotate(180deg); }
        .Dropdown {
            max-height: 160px; } }
    select {
        width: 100%;
        font-size: 14px;
        opacity: 1;
        &:focus {
            outline: none; } }

    &:last-of-type {
        margin-bottom: 20px;
        border-bottom: $border-style; } }

.Radio {
    display: flex;
    height: 40px;
    input {
        position: absolute;
        visibility: hidden;
        &:checked + label {
            color: white!important; } }
    label {
        cursor: pointer;
        border: $border-style;
        border-bottom: none;
        border-left: none;
        color: rgba(255,255,255,.3);
        font-size: .7em;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-of-type {
            border-left: $border-style; } }
    &:last-child {
        label {
            border-bottom: $border-style; } } }

.split_f {
    border-left: $border-style!important; }


.Textarea {
    min-height: 60px;
    border: $border-style;
    border-bottom: none;
    padding: 0;
    position: relative;
    overflow: hidden;
    clear: both;
    font-family: "HelveticaRoman";
    &.split2 {
        width: 50%;
        float: left;
        clear: initial;
        & + .split2 {
            margin-left: 0px;
            float: left;
            border-left: $border-style; } }
    &.split3 {
        width: calc(100% / 3);
        float: left;
        clear: initial;
        & + .split3 {
            margin-left: 0px;
            float: left;
            border-left: $border-style; } }
    &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px; }
    &:last-of-type {
        margin-bottom: 20px;
        border-bottom: $border-style;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px; }
    & + .SubmitButtons {
        border-top: $border-style; }
    .Validity {
        font-size: 20px;
        display: none;
        line-height: 50px;
        color: white;
        text-shadow: 0 0 1px rgba(0,0,0,.7);
        &.fa-check-circle {
            color: #33d9b2; }
        &.fa-times-circle {
            color: #ff5252; } }
    label {
        position: absolute;
        top: 20px;
        cursor: text;
        line-height: 20px;
        left: 15px;
        width: 100%;
        color: rgba(255,255,255,.8);
        display: block;
        text-align: left;
        font-size: 13px;
        transform-origin: left;
        transition: .3s ease;
        z-index: 1; }
    textarea {
        resize: none;
        position: absolute;
        left: 0;
        top: 0;
        overflow: auto;
        z-index: 2;
        height: 100%;
        margin-top: 30px;
        padding: 0px 15px 0px 15px;
        width: 100%;
        color: white;
        border: none;
        font-size: 15px;
        background: none;
        &:focus {
            outline: none; } }
    &.focused {
        label {
            transform: translateY(-12px);
            color: rgba(255,255,255,.4); } } }

.Input {
    height: 60px;
    border: $border-style;
    border-bottom: none;
    padding: 0;
    position: relative;
    clear: both;
    font-family: "HelveticaRoman";
    &.split2 {
        width: 50%;
        float: left;
        clear: initial;
        & + .split2 {
            margin-left: 0px;
            float: left;
            border-left: none; } }
    &.split3 {
        width: calc(100% / 3);
        float: left;
        clear: initial;
        & + .split3 {
            margin-left: 0px;
            float: left;
            border-left: none; } }
    &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px; }
    &:last-of-type {
        margin-bottom: 20px;
        border-bottom: $border-style;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px; }
    & + .SubmitButtons {
        border-top: $border-style; }
    .Validity {
        font-size: 20px;
        display: none;
        line-height: 50px;
        color: white;
        text-shadow: 0 0 1px rgba(0,0,0,.7);
        &.fa-check-circle {
            color: #33d9b2; }
        &.fa-times-circle {
            color: #ff5252; } }
    label {
        position: absolute;
        top: 20px;
        cursor: text;
        line-height: 20px;
        left: 15px;
        width: 100%;
        color: rgba(255,255,255,.8);
        display: block;
        text-align: left;
        font-size: 13px;
        transform-origin: left;
        transition: .3s ease;
        z-index: 1; }
    input {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        height: 100%;
        padding: 19px 15px 0px 15px;
        width: 100%;
        color: white;
        border: none;
        font-size: 15px;
        background: none;
        &:focus {
            outline: none; } }
    &.focused {
        label {
            transform: translateY(-12px);
            color: rgba(255,255,255,.4); } } }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    font-family: 'Montserrat', sans-serif;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s; }

.SubmitButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .SubmitButton {
        flex-grow: 1;
        border: none;
        height: 50px;
        background: $company-color;
        background-size: 100% 300%;
        background-position: center 0%;
        font-size: 16px;
        color: white;
        text-transform: uppercase;
        font-weight: 600;
        text-shadow: 0 0px 0px rgba(184,93,7,.9);
        cursor: not-allowed;
        transition: .3s ease;
        position: relative;
        border-bottom-left-radius: 5px;
        overflow: hidden;
        border: 2px solid $company-color;
        color: $button-text;
        border-top: none;
        &:last-of-type {
            background: black;
            color: white;
            margin-right: 0;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 0px; }
        .SubmitTitle, .LoadingTitle {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            line-height: 50px;
            text-align: center;
            transition: .3s ease; }
        .LoadingTitle {
            opacity: 0;
            transform: translateY(100%); }
        &.loading {
            .LoadingTitle {
                opacity: 1;
                transform: translateY(0); }
            .SubmitTitle {
                opacity: 0;
                transform: translateY(-100%); } }
        &:focus {
            outline: none; }
        &.active {
            background-position: center 50%;
            cursor: pointer;
            text-shadow: 0 1px 1px rgba(184,93,7,.9);
            &:hover {
                background-position: center 100%;
                opacity: .9; } } } }

input:-webkit-autofill + label,
input:-webkit-autofill:hover + label,
input:-webkit-autofill:focus + label, {
    transform: translateY(-12px);
    color: rgba(255,255,255,.4); }


.FormWrapper {
    width: 85%;
    margin: 5px 7.5% 0;
    .endForm {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom: $border-style; }
    .InputError {
        color: #f05e45;
        font-size: 0.7em;
        padding-left: 5px; }
    @include screen-m {
        width: 90%;
        margin: 5px 5% 0;
        .CheckBoxWrapper {
            margin-bottom: 30px!important;
            .CheckBoxTitle {
                top: 5px!important;
                font-size: 14px!important;
                line-height: 18px!important; } } } }

.FormInfo {
    background: $blue-light;
    width: 85%;
    margin: 10px 7.5% 0;
    display: flex;
    padding: 10px 15px;
    align-items: center;
    .InfoIcon {
        font-size: 30px;
        margin-right: 15px;
        color: #484f5f; }
    div {
        text-align: left;
        font-size: 14px;
        line-height: 17px;
        opacity: .6; }
    p:not(:last-of-type) {
        margin-bottom: 5px; }
    span {
        cursor: pointer;
        border-bottom: 1px dotted black; } }
.FormInfoActions {
    width: 85%;
    margin: 2px 7.5% 20px;
    display: flex;
    align-items: stretch;
    button {
        flex-grow: 1;
        border: none;
        background: rgba(92,101,120,1);
        height: 40px;
        cursor: pointer;
        margin-right: 2px;
        color: rgba(255,255,255,.8);
        font-size: 14px;
        transition: color .3s ease, background .3s ease;
        &:focus {
            outline: none; }
        i {
            opacity: .7;
            padding-right: 3px; }
        &:hover {
            color: white;
            background: rgba(92,101,120,.7); }
        &:last-of-type {
            margin-right: 0; } } }
